@font-face {
  font-display: swap;
  font-family: "IT";
  font-weight: 125 950;
  font-stretch: 75% 125%;
  font-style: normal;
  src: url("InterTight-VariableFont_wght.woff2") format("woff2-variations");
}

@font-face {
  font-display: swap;
  font-family: "NSM";
  font-weight: 125 950;
  font-stretch: 75% 125%;
  font-style: oblique 0deg 20deg;
  src: url("NotoSansMono-VariableFont_wdth,wght.woff2") format("woff2-variations");
}
